"use client";
import useKeyPress from "@src/hooks/useKeyPress";
import NextTopLoader from "nextjs-toploader";
import "@src/styles/globals.css";
import Context from "@src/utils/context";
import { useEffect, useState } from "react";
import { createPagesBrowserClient } from '@supabase/auth-helpers-nextjs';
import { SessionContextProvider, Session } from '@supabase/auth-helpers-react';
import SupabaseAuthProvider from "@src/utils/SupabaseAuthContext";
import { ToastContainer } from "react-toastify";
import { SearchBody } from "@src/component";
import { useClickOutside } from "@mantine/hooks";
import "react-toastify/dist/ReactToastify.css";
import ScriptComponent from "@src/component/Scripts";
import { CSPostHogProvider } from "@src/utils/postHog";
export default function RootLayout({
  children
}: {
  children: React.ReactNode;
}) {
  const [searchOpen, setSearchOpen] = useState(false);
  const ref = useClickOutside<HTMLDivElement>(() => setSearchOpen(false));
  const handleKeyPress = (): void => {
    setSearchOpen(true);
  };
  useKeyPress(handleKeyPress);
  useEffect(() => {
    if (searchOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [searchOpen]);
  const [supabaseClient] = useState(() => createPagesBrowserClient());
  return <html lang="en">
    <body>
      {/* Layout UI */}
      <main>
        <ScriptComponent />
        {/* <CSPostHogProvider> */}
          <SessionContextProvider supabaseClient={supabaseClient}
          // initialSession={pageProps.initialSession}
          >
            <Context options={{
              searchOpen,
              setSearchOpen
            }}>
              <SupabaseAuthProvider>
                <ToastContainer closeButton={false} pauseOnFocusLoss={false} toastClassName={() => "relative bg-white dark:bg-zinc-900 text-neutral-800 dark:text-white flex p-1 min-h-15 rounded-md justify-between overflow-hidden cursor-pointer p-5 border-2 dark:border-zinc-800 :dark:fill:slate-50 mb-4"} />
                {/* <NextTopLoader color="#2563eb" /> */}
                {children}
                {searchOpen && <SearchBody ref={ref} setOpened={setSearchOpen} />}
              </SupabaseAuthProvider>
            </Context>
          </SessionContextProvider>
        {/* </CSPostHogProvider> */}
      </main>
     </body>
  </html>;
}