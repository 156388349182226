import Script from 'next/script';
import React from 'react';

function ScriptComponent() {
  return (
    <>
      <Script strategy='afterInteractive' id="gtm-head-script">
        {`
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-P99W5DQW');
                `}
      </Script>
      <Script strategy='afterInteractive' id="gtm-body-script">
        {`
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-P99W5DQW');
                `}
      </Script>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=G-C02B744HEC`} strategy='afterInteractive' id="google-analytics-script" />
      <Script id="google-analytics-inline-script" strategy='afterInteractive'>
        {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'G-C02B744HEC');
                `}
      </Script>
      <Script src={`https://js.sparkloop.app/team_71a48bd5d0d5.js`} strategy='afterInteractive' data-sparkloop />
    </>
  );
}

export default ScriptComponent;
